<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12" v-if="marketplace == 'paybox' || marketplace == 'sogecommerce'">
				<div class="box">
					<b-form-checkbox id="contract_invoice_mode" v-model="contract_invoice_mode" :disabled="!can_save"><label for="contract_invoice_mode">{{ $t("settings.client_area.invoice_contract_mode") }}</label></b-form-checkbox>
					<div class="form-group">
						<button class="btn btn-primary" @click='save_contract_invoice_mode'>
							{{$t("global.sauvegarder")}}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="box">
					<CustomTable 
						ref="table"
						id_table="contract_conditions_invoice_client_area"
						primaryKey="contractconditions_id"
						sortBy="contractconditions_type"
						:items="items"
						:busy.sync="table_busy"
						:columsAdd="['enable_invoice_ca']"
						:externSlotColumns="['contractconditions_label', 'enable_invoice_ca']"
					>
						<template v-slot:[`custom-slot-cell(contractconditions_label)`]="{ data }">
							{{ $t(`monte.${data.contractconditions_type}`) }}
						</template>
						<template v-slot:[`custom-slot-cell(enable_invoice_ca)`]="{ data }">
							<b-form-checkbox
								class="custom-control custom-checkbox float-right mr-2"
								v-model="data.ca_invoice.condcainvoice_enabled"
								@input="switchAutoInvoiceCa(data)"
								name="check-auto-invoice-ca" 
								switch
							>
							</b-form-checkbox>
						</template>
					</CustomTable>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
    import Contract from "@/mixins/Contract.js"
    import Marketplace from "@/mixins/Marketplace.js"
	import _defaults from 'lodash/defaults'

	export default {
		name: "AutoInvoice",
		mixins: [Contract, Marketplace],
		data () {
			return {
				table_busy: true,
				items: [],
				contract_invoice_mode: false,
				can_save: false,
				marketplace: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.can_save = false
				this.items = await this.getConditionsMonteCaInvoice()
				this.table_busy = false

				let marketplace = await this.getMarketplaceTypeNotArea()
				this.marketplace = marketplace.type


				const ca_config = await this.getCaContractInvoiceMode()

				const temp = _defaults(ca_config, {
					contract_invoice_mode: "after",
				})

				if(temp.contract_invoice_mode == "before"){
					this.contract_invoice_mode = true
				}
				
				this.can_save = true
			},
			
			async switchAutoInvoiceCa(contractcondition) {
				this.setConditionsMonteCaInvoice(contractcondition.contractconditions_id, contractcondition.ca_invoice.condcainvoice_enabled)
					.then(() => {
						this.successToast()
					})
			},
			save_contract_invoice_mode(){
				this.can_save = false

				this.saveCaContractInvoiceMode((this.contract_invoice_mode) ? "before" : "after").then(() => {
					this.successToast()
					this.can_save = true
				})

			}
		},
		watch: {
			
		},
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}
</script>
